<template>
  <v-container id="processing_form_template_list">
    <div>
      <h1 v-html="$t('processing_form_template_read_title')"></h1>

      <div class="row">
        <div class="col-sm-6 col-md-4">
          <v-text-field ref="dairyIdent" name="dairyIdent" id="dairy_ident" v-model="dairyIdent" @keyup.enter="search" :label="'MBH-ID ' + testadressen" />
        </div>
        <div class="col-sm-6 col-md-4">
          <v-btn id="btn_search" color="primary" @click="search">
            <span v-html="$t('processing_form_template_read_search')" />
          </v-btn>
        </div>
      </div>

      <v-data-table-server
        :headers="fields"
        :items="items"
        :fields="fields"
        :loading="loading"
        :items-length="totalElements"
        @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(query.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
        :items-per-page-options="$itemsPerPageOptions"
        density="compact"
      >
        <template v-slot:[`item.edit`]="{ item }">
          <div v-if="item.id">
            <router-link
              :to="{
                name: 'processingFormTemplates_update',
                params: { id: parseInt(item.id), tab: 'overview' }
              }"
              class="tableaction link"
            >
              <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
            </router-link>
          </div>
          <div v-else>
            <router-link
              :to="{
                name: 'processingFormTemplates_create',
                query: {
                  id: item.dairyId,
                  dairyIdent: item.dairyIdent
                }
              }"
              class="tableaction link"
            >
              <span v-html="$t('new')" />
            </router-link>
          </div>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <div v-if="item.id">
            <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
              <router-link
                :to="{
                  name: 'masterdata_dairies_edit',
                  params: { id: item.dairyId, tab: 'overview' }
                }"
              >
                {{ item.dairyIdent ? item.dairyIdent : $t('none') }}
              </router-link>
            </template>
          </div>
          <div v-else>
            <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
              <router-link
                :to="{
                  name: 'masterdata_dairies_edit',
                  params: { id: item.dairyId, tab: 'overview' }
                }"
              >
                {{ item.dairyIdent ? item.dairyIdent : $t('none') }}
              </router-link>
            </template>
          </div>
        </template>
        <template v-slot:[`item.dairyPersonInitials`]="{ item }">{{ item.dairyPersonInitials ? item.dairyPersonInitials : ' - ' }} </template>
      </v-data-table-server>
    </div>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import _ from 'lodash'
import { showError } from '@/services/axios'
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'findOrCreate',
  data() {
    return {
      showTSM1CreateBtn: false,
      items: [],
      loading: false,
      totalElements: 0,
      dairyIdent: this.$route.query?.dairyIdent ? this.$route.query.dairyIdent : null,
      testadressen: process.env.NODE_ENV !== 'production' ? 'Testadressen: 303, 681, 8690' : '',
      //schema: schema,
      fields: <DTSHeader[]>[
        // Bearbeiten
        {
          title: this.$t('processing_form_table_header_edit'),
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        // Verwerter-ID
        {
          title: this.$t('processing_form_table_header_dairy_ident'),
          key: 'id',
          sortable: true,
          align: 'left'
        },
        // Name
        {
          title: this.$t('processing_form_table_header_name'),
          key: 'dairyName',
          sortable: true
        },
        // Strasse
        {
          key: 'dairyStreet',
          title: this.$t('processing_form_table_header_street'),
          sortable: false
        },
        // Postleitzahl
        {
          title: this.$t('processing_form_table_header_zip'),
          key: 'dairyZip',
          sortable: true
        },
        // Ort
        {
          title: this.$t('processing_form_table_header_locality'),
          key: 'dairyLocality',
          sortable: true
        },
        // Zuständig
        {
          title: this.$t('processing_form_template_table_header_responsible_employee'),
          key: 'dairyPersonInitials',
          sortable: false
        }
      ],
      productGroups: [],
      selected: undefined,
      term: { nameDe: '' }
    }
  },
  computed: {
    params() {
      return { dairyIdent: this.dairyIdent }
    },
    query(): any {
      return {
        ...this.$route.query,
        ...this.params,
        ...this.getJavaPageOptions()
      }
    }
  },
  methods: {
    async refreshCreatBtn(dairyIdent) {
      this.showTSM1CreateBtn = false
      if (!Number.isNaN(dairyIdent)) {
        this.hasTSM1Data(dairyIdent)
      }
    },
    async hasTSM1Data(dairyIdent) {
      try {
        const response = await this.axios.get(apiURL + '/processingForms/find', {
          params: {
            dairyIdent: dairyIdent
          }
        })
        const data = await response.data
        if (data.numberOfElements > 0) {
          this.showTSM1CreateBtn = true
        } else {
          this.showTSM1CreateBtn = false
        }
      } catch (e) {
        showError(e)
      }
    },
    search() {
      this.$router
        .push({
          name: 'processingFormTemplates_read',
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load(this.query)
        })
    },
    async load(query) {
      try {
        this.loading = true
        this.items = []
        const response = await this.axios.get(apiURL + '/processingFormTemplates/findOrCreate', { params: query })
        const items = response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue) {
        this.load(this.$route.query)
      },
      deep: true
    }
  },
  mounted() {
    this.$refs.dairyIdent.focus()
    if (this.query.dairyIdent) {
      this.load(this.query)
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/styles/colors.scss';
@import '@/styles/variables.scss';

.search-button-position {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.button-margin-left {
  margin-left: $spacer-xs !important;
}

.aligned-buttons {
  display: flex !important;
}
</style>
